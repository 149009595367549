import React from "react";
import { withRouter } from 'react-router-dom';
import { getRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "components/LoadSpinner";
import { FormGroup, Input, Label } from "reactstrap";
import CloseIcon from "components/icons/CloseIcon";
import ActionButton from "components/ActionButton";
import PhoneInput from 'react-phone-number-input'

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    GetCountries,
    GetState,
} from "react-country-state-city"
import "./react-country-state-city.css";

class CreatorAppProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            countryId: "",
            country: "",
            countries: null,
            countryObj: null,
            stateObj: null,
            stateId: "",
            state: "",
            usStates: null,
            states: null,
            sectors: [],
            allSectors: [],
            stillAvailableSectors: [],
            fetchingSectors: true,
            toggleAdditionalCategories: false,
            agreeToTerms: false,
            anonymous: false,
            userInformation: {
                id: 0,
                name: "",
                email: "",
                address: "",
                city: "",
                state: "",
                country: "",
                website: "",
                postalCode: "",
                phoneNumber: "",
                tagLine: "",
                bio: "",
                userName: "",
                googleUsername: "",
                facebookUsername: "",
                instagramUsername: "",
                tikTokUsername: "",
                stravaUsername: "",
            },
        };
    }

    componentDidMount = () => {
        GetCountries().then((countries) => {
            this.setState({ countries });
            this.setCountryAndStateIfAvailableFromLoggedInUser();
        });
        GetState(233).then((usStates) => {
            this.setState({ usStates });
        });        
        if (!(this.props.userInformation)) {
            this.setState({ anonymous: true });
        }

        this.getSectors(!(this.props.userInformation));

    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.userInformation !== prevState.userInformation) {
            this.props.updateUserInformation(this.state.userInformation);
        }
        if (this.props.cancelEdit) {
            this.parseUserInfo();
            this.setPersistentSectors();
            this.props.resetCancelEdit();
        }
    }

    fetchStates = (countryId) => {
        if (!countryId) return;
        GetState(countryId).then((states) => {
            this.setState({ states });
        });
    };

    setCountryAndStateIfAvailableFromLoggedInUser = () => {
        let country = this.props.loggedInUser?.country;
        let state = this.props.loggedInUser?.state;

        if (!!(country) && this.state.countries?.length > 0) {
            let countryObjArray = this.state.countries.filter(x => x.name.toLowerCase() === country.toLowerCase() || x.iso2.toLowerCase() === country.toLowerCase() || x.iso3?.toLowerCase() === country.toLowerCase());
            if (countryObjArray.length > 0) {
                let countryObj = countryObjArray[0];
                this.setState({ countryId: countryObj.id, country: countryObj.name, countryObj });

                if (!!(state)) {
                    GetState(countryObj.id).then((states) => {
                        let stateObjArray = states?.filter(x => x.name?.toLowerCase() === state?.toLowerCase() || x.state_code?.toLowerCase() === state?.toLowerCase());

                        if (stateObjArray.length > 0) {
                            let stateObj = stateObjArray[0];
                            this.setState({ stateId: stateObj.id, state: stateObj.name, stateObj });
                        }
                    });
                }
            } else if (!!(state)) {
                GetState(233).then((states) => {
                    let stateObjArray = states?.filter(x => x.name?.toLowerCase() === state?.toLowerCase() || x.state_code?.toLowerCase() === state?.toLowerCase());

                    if (stateObjArray.length > 0) {
                        let stateObj = stateObjArray[0];
                        let countryObj = this.state.countries?.filter(x => x.id === 233)[0];
                        this.setState({ countryId: countryObj.id, country: countryObj.name, countryObj, stateId: stateObj.id, state: stateObj.name, stateObj });
                    }
                })
            } else {
                this.setState({ country: this.props.loggedInUser?.country?.length > 0 ? country : "", state: this.props.loggedInUser?.state?.length > 0 ? state : "" });
            }
        } else if (!!(state)) {
            GetState(233).then((states) => {
                let stateObjArray = states?.filter(x => x.name?.toLowerCase() === state?.toLowerCase() || x.state_code?.toLowerCase() === state?.toLowerCase());

                if (stateObjArray.length > 0) {
                    let stateObj = stateObjArray[0];
                    let countryObj = this.state.countries?.filter(x => x.id === 233)[0];
                    this.setState({ countryId: countryObj.id, country: countryObj.name, countryObj, stateId: stateObj.id, state: stateObj.name, stateObj });                }
            });
        } else {
            this.setState({ country: this.props.loggedInUser?.country?.length > 0 ? country : "", state: this.props.loggedInUser?.state?.length > 0 ? state : "" });
        }
    }

    setPersistentSectors = () => {
        if (!!(this.props.persistentSectors)) {
            const sectors = this.props.persistentSectors;

            const stillAvailableSectors = this.state.allSectors.filter((allSector) => {
                return !sectors.find((sector) => {
                    return allSector.sectorId === sector.sectorId
                })
            });

            this.setState({ sectors, stillAvailableSectors });
        }
    }

    addUserSector = (sector) => {
        const sectors = [...this.state.sectors, sector];
        const stillAvailableSectors = this.state.stillAvailableSectors.filter(s => s.sectorId !== sector.sectorId)
        this.setState({ sectors, stillAvailableSectors });
        this.props.updateSectors(sectors);
    }

    deleteUserSector = (sector) => {
        const sectors = this.state.sectors.filter(s => s.sectorId !== sector.sectorId)
        this.setState({ sectors, stillAvailableSectors: [sector, ...this.state.stillAvailableSectors] });
        this.props.updateSectors(sectors);
    }

    parseUserInfo = () => {
        if (!!(this.props.userInformation)) {
            const propsUserInformation = !!(this.props.persistentUserInformation) ? this.props.persistentUserInformation : this.props.userInformation;

            const userInformation = {
                name: propsUserInformation.name ?? "",
                firstName: propsUserInformation.firstName ?? "",
                lastName: propsUserInformation.lastName ?? "",
                email: propsUserInformation.email ?? "",
                address: propsUserInformation.address ?? "",
                website: propsUserInformation.website ?? "",
                phoneNumber: propsUserInformation.phoneNumber ?? "",
                tagLine: propsUserInformation.tagLine ?? "",
                bio: propsUserInformation.bio ?? "",
                userName: propsUserInformation.userName ?? "",

                city: propsUserInformation.city ?? "",
                state: propsUserInformation.state ?? "",
                postalCode: propsUserInformation.postalCode ?? "",
                country: propsUserInformation.country ?? "",

                googleUsername: propsUserInformation.googleUsername ?? "",
                facebookUsername: propsUserInformation.facebookUsername ?? "",
                instagramUsername: propsUserInformation.instagramUsername ?? "",
                tikTokUsername: propsUserInformation.tikTokUsername ?? "",
                stravaUsername: propsUserInformation.stravaUsername ?? "",
            };
            this.setState({ userInformation })
        }
    }

    getSectors = async (anonymous) => {
        let sectors = await getRequest('/api/Sector/GetSectors') ?? [];
        if (anonymous) {
            this.setState({
                allSectors: sectors,
                sectors: [],
                stillAvailableSectors: sectors,
                fetchingSectors: false,
                toggleAdditionalCategories: true,
            });
            this.props.setSectors([], sectors);

            return;
        }
        let userSectors = await getRequest(`/api/Sector/GetSectorsByUser?userid=${this.props.userInformation.id}`) ?? [];
        let activeSectors = [];

        for (var i = 0; i < userSectors.length; ++i) {
            for (var j = 0; j < sectors.length; ++j) {
                if (userSectors[i].sectorId === sectors[j].sectorId) {
                    activeSectors.push(sectors[j]);
                    break;
                }
            }
        }

        const stillAvailableSectors = sectors.filter(s => !activeSectors.includes(s));

        this.setState({
            allSectors: sectors,
            sectors: activeSectors,
            stillAvailableSectors,
            fetchingSectors: false,
            toggleAdditionalCategories: activeSectors?.length === 0 ? true : this.state.toggleAdditionalCategories,
        });
        this.props.setSectors(activeSectors, sectors);
    }

    renderSectors = () => {
        const { fetchingSectors, sectors } = this.state;

        if (fetchingSectors === true) {
            return <LoadSpinner />
        } else if (sectors.length === 0) {
            return <p className="" style={{ display: "inline" }}>None</p>
        }
        else {
            return sectors.map((sector, i) => {
                return <button key={i} className="btn d-flex align-items-center border" disabled>{sector.name}</button>
            });
        }
    }

    onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        if (formKey === 'bio') {
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }
        const userInformation = this.state.userInformation;
        userInformation[formKey] = newData;

        this.setState({ userInformation });
        this.props.updateUserInformation(userInformation);
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.saveBasicSettings(this.state.userInformation);
    }

    downwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg>
        )
    }

    upwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
        )
    }

    handleHearAboutUsChange = async e => {
        await this.props.onInputChange(e);
    };

    mapInputFields = (fields) => {
        return fields.map((field, i) => {
            if (!!(field.name) && field.name.includes("-")) {
                const [id, name] = field.name.split('-'); // Splitting the inputName into id and field
                return (
                    <FormGroup key={i} className="form-group">
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => this.props.onInputAdditionalPlatformsChange(e)}
                            value={this.props.formData.additionalPlatforms.find(item => item.id === id)?.followers}
                        />
                    </FormGroup>
                );
            }
            return (
                <FormGroup key={i} className="form-group">
                    {
                        !!(field.title) ?
                            <Label className="control-label">{field.title}</Label>
                            :
                            <></>
                    }
                    <Input
                        className="form-control"
                        name={field.name}
                        onChange={(e) => this.props.onInputChange(e)}
                        value={this.props.formData[field.name]}
                    />
                </FormGroup>
            );
        });
    }

    renderEditSectors = () => {
        return (
            <div style={{ maxWidth: "500px" }}>
                <div className="mb-1" style={{ fontSize: "12px", color: "gray" }}>What are you most passionate about?</div>

                <h6>My Passion List</h6>
                <div>
                    <ul>
                        {
                            this.state.sectors.length === 0 ?
                                <p className="" style={{ display: "inline" }}>None</p>
                                :
                                this.state.sectors.map((sector, i) => {
                                    return (
                                        <li key={i} className="d-flex justify-content-between pr-5">
                                            <div>
                                                {sector.name}
                                            </div>
                                            <div style={{ cursor: "pointer" }} onClick={() => this.deleteUserSector(sector)}>
                                                <CloseIcon browse={true} width={15} height={15} />
                                            </div>
                                        </li>
                                    );
                                })
                        }
                    </ul>
                </div>
                <div>
                    <div className="d-flex justify-content-between" style={{ cursor: "pointer" }} onClick={() => this.setState({ toggleAdditionalCategories: !this.state.toggleAdditionalCategories })}>
                        <h6>Additional Categories</h6>
                        {this.state.toggleAdditionalCategories ? this.upwardChevron() : this.downwardChevron()}
                    </div>
                    <div style={{ display: this.state.toggleAdditionalCategories ? "" : "none" }}>
                        <ul className="creator-dashboard-products-scroll pr-3" style={{ maxHeight: "200px", overflowY: "auto" }}>
                            {
                                this.state.stillAvailableSectors.map((sector, i) => {
                                    return (
                                        <li key={i} className="d-flex justify-content-between">
                                            <div>
                                                {sector.name}
                                            </div>
                                            <div style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => this.addUserSector(sector)}>
                                                Add
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

        );
    }

    renderAdditionalPlatforms = () => {
        return this.props.formData.additionalPlatforms.map((platformObj, i) => {
            if (i === this.props.formData.additionalPlatforms.length - 1) {
                return (
                    <>
                        <div key={i} className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-0"}`}>
                            <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                <div>
                                    <span style={{ fontSize: "13px", color: "gray" }}>Additional Social Platform (not required)</span>
                                </div>

                                <FormGroup className="form-group">
                                    <select id="additionalPlatform-platform"
                                        className="form-control pt-0 pb-0"
                                        name="additionalPlatform-platform"
                                        onChange={(e) => this.props.onInputAdditionalPlatformsChange(e)}
                                        value={this.props.formData.additionalPlatforms.find(item => item.id === "additionalPlatform")?.platform || ''}
                                    >
                                        <option style={{ display: "none" }} />
                                        <option value="Instagram">Instagram</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="YouTube">YouTube</option>
                                        <option value="TikTok">TikTok</option>
                                        <option value="Website/Blog">Website/Blog</option>
                                    </select>
                                </FormGroup>
                            </div>

                            {this.showPlatformUserName("additionalPlatform")}

                            <div className={`col-lg-4`}>
                                <div>
                                    <span style={{ fontSize: "13px", color: "gray" }}>Follower or Monthly Traffic Count</span>
                                </div>
                                {this.mapInputFields([{ name: "additionalPlatform-followers" }])}
                            </div>
                        </div>
                        <div className={`mb-5`}>
                            <a href="#" onClick={(e) => { e.preventDefault(); this.props.addAdditionalPlatform(); }} style={{ color: this.props.isAdditionalPlatformAddable() ? "black" : "lightgray", textDecoration: "underline", cursor: this.props.isAdditionalPlatformAddable() ? "" : "unset" }}>Add Additional Platform</a>
                        </div>
                    </>
                );
            }
            return (
                <>
                    <div key={i} className={`row position-relative ${["sm", "md"].includes(this.props.deviceSize) ? "commissions-chart-detail mb-5" : ""}`}>
                        <div className={` position-relative col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <a href="#" onClick={(e) => { e.preventDefault(); this.props.deleteAdditionalPlatform(platformObj.id); }} style={{ display: !["sm", "md"].includes(this.props.deviceSize) || i === this.props.formData.additionalPlatforms.length - 1 ? "none" : "", position: "absolute", top: "4px", right: "17px", textDecoration: "underline", color: "black", fontSize: "10px",  }}>remove platform</a>

                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Additional Social Platform (not required)</span>
                            </div>

                            <FormGroup className="form-group">
                                <select id={platformObj.id + "-platform"}
                                    className="form-control pt-0 pb-0"
                                    name={platformObj.id + "-platform"}
                                    onChange={(e) => this.props.onInputAdditionalPlatformsChange(e)}
                                    value={this.props.formData.additionalPlatforms.find(item => item.id === platformObj.id)?.platform || ''}
                                >
                                    <option style={{ display: "none" }} />
                                    <option value="Instagram">Instagram</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="YouTube">YouTube</option>
                                    <option value="TikTok">TikTok</option>
                                    <option value="Website/Blog">Website/Blog</option>
                                </select>
                            </FormGroup>
                        </div>

                        {this.showPlatformUserName(platformObj.id)}

                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Follower or Monthly Traffic Count</span>
                            </div>
                            {this.mapInputFields([{ name: platformObj.id + "-followers" }])}
                        </div>
                        <a href="#" onClick={(e) => { e.preventDefault(); this.props.deleteAdditionalPlatform(platformObj.id); }} style={{ display: ["sm", "md"].includes(this.props.deviceSize) || i === this.props.formData.additionalPlatforms.length - 1 ? "none" : "", position: "absolute", bottom: "-4px", right: "17px", textDecoration: "underline", color: "black", fontSize: "10px", }}>remove platform</a>

                    </div>
                </>
            );
        })
    }


    isReadyToApply = () => {
        const dynamicQuestionsReady = this.props.dynamicQuestions?.length > 0
            ? this.props.dynamicQuestions.every(question => !!(this.props.formData[question.label]?.trim()))
            : true;

        if (this.state.anonymous) {
            return (
                !!(this.props.formData?.firstName?.trim()) &&
                !!(this.props.formData?.lastName?.trim()) &&
                !!(this.props.formData?.userName?.trim()) &&
                !!(this.props.formData?.city?.trim()) &&
                !!(this.props.formData?.state?.trim()) &&
                !!(this.props.formData?.country?.trim()) &&
                !!(this.props.formData?.email?.trim()) &&
                !!(this.props.formData?.phoneNumber?.trim()) &&
                !!(this.props.formData?.platform?.trim()) &&
                (!!(this.props.formData?.platformUsername?.trim()) || this.props.formData.platform === "Website/Blog") &&
                !!(this.props.formData?.followers?.trim()) &&
                !this.props.emailExists &&
                this.props.validEmail &&
                this.props.validPassword &&
                this.props.validUserName &&
                !this.props.usernameExists &&
                this.props.formData?.password?.length > 0 &&
                this.props.formData?.userName?.length > 0 &&
                this.props.formData?.email?.length > 0 &&
                this.props.formData?.password === this.props.formData.confirmedPassword &&
                this.props.formData?.email === this.props.formData.confirmedEmail &&
                this.props.agreeToTerms &&
                dynamicQuestionsReady
            );
        }
        return (
            !!(this.props.formData?.firstName?.trim()) &&
            !!(this.props.formData?.lastName?.trim()) &&
            !!(this.props.formData?.userName?.trim()) &&
            !!(this.props.formData?.city?.trim()) &&
            !!(this.props.formData?.state?.trim()) &&
            !!(this.props.formData?.country?.trim()) &&
            !!(this.props.formData?.email?.trim()) &&
            !!(this.props.formData?.phoneNumber?.trim()) &&
            !!(this.props.formData?.platform?.trim()) &&
            (!!(this.props.formData?.platformUsername?.trim()) || this.props.formData.platform === "Website/Blog") &&
            !!(this.props.formData?.followers?.trim()) &&
            !this.props.emailExists &&
            this.props.validEmail &&
            this.props.formData?.email?.length > 0 &&
            this.props.agreeToTerms &&
            dynamicQuestionsReady
        );
    }


    showPlatformUserName = (id) => {
        let platforms = [
            { name: "instagram", title: "Instagram Username", prepend: 'www.instagram.com/' },
            { name: "facebook", title: "Facebook Username", prepend: 'www.facebook.com/' },
            { name: "youtube", title: "YouTube Channel", prepend: 'www.youtube.com/' },
            { name: "tiktok", title: "TikTok Username", prepend: 'www.tiktok.com/@' },
        ];

        if (!!(id) && this.props.formData.additionalPlatforms.some(item => item.id === id && !!(item.platform))) {
            //platforms = platforms.filter(item =>
            //    !this.props.additionalPlatforms.some(additionalPlatform =>
            //        additionalPlatform.platform.toLowerCase() === item.platform.toLowerCase()
            //    )
            //);

            //let platform = this.props.formData.platformTemp?.toLowerCase() ?? "";
            //if (!this.state.showPlatformTempUserNameMounted && this.props.formData["platformTempUsername"] === "") {
            //    this.props.formData[id + "-userName"] = !!this.props.formData[platform] ? this.props.formData[platform] : "";
            //}
            //if (!this.state.showPlatformTempUserNameMounted) {
            //    this.setState({ showPlatformTempUserNameMounted: true });
            //}

            let platformUserName = this.props.formData.additionalPlatforms.find(item => item.id === id)?.platform;

            let selectedPlatform = platforms.filter((p) => p.name === platformUserName.toLowerCase())[0];

            return (
                <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                    <div>
                        <span style={{ fontSize: "13px", color: "gray" }}>{selectedPlatform?.title}</span>
                    </div>
                    <FormGroup className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">{selectedPlatform?.prepend}</span>
                            </div>
                            <Input
                                className="form-control"
                                name={`${id}-userName`} // Dynamically generate the name attribute
                                onChange={(e) => this.props.onInputAdditionalPlatformsChange(e)}
                                value={this.props.formData.additionalPlatforms.find(item => item.id === id)?.userName || ''}
                            />
                        </div>
                    </FormGroup>

                </div>

            );
        } else if (!(id) && this.props.formData.platform !== "" && this.props.formData.platform !== "Website/Blog") {
            let platform = this.props.formData.platform.toLowerCase();
            if (!this.state.showPlatformUserNameMounted && this.props.formData["platformUsername"] === "") {
                this.props.formData["platformUsername"] = !!this.props.formData[platform] ? this.props.formData[platform] : "";
            }
            if (!this.state.showPlatformUserNameMounted) {
                this.setState({ showPlatformUserNameMounted: true });
            }

            let selectedPlatform = platforms.filter((p) => p.name === platform)[0];

            return (
                <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                    <div>
                        <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.platformUsername.length === 0 ? "red" : "gray" }}>{selectedPlatform.title}<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.platformUsername.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.platformUsername.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                    </div>
                    <FormGroup className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">{selectedPlatform.prepend}</span>
                            </div>
                            <Input
                                className="form-control"
                                name="platformUsername"
                                onChange={(e) => this.props.onInputChange(e)}
                                value={this.props.formData["platformUsername"]}
                            />
                        </div>
                    </FormGroup>

                </div>

            );
        }
    };

    handleSubmit = () => {

        if (this.isReadyToApply()) {
            this.props.apply();
        } else {
            this.props.highlightRequiredFields();
        }
    }

    renderDynamicQuestions = () => {
        if (this.props.dynamicQuestions?.length > 0) {
            return this.props.dynamicQuestions.map((question, i) => {
                const title = question.title;
                const label = question.label;
                const options = question.options;

                return (
                    <div key={i} className={`col-8 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                        <div>
                            <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData[label]?.length === 0 ? "red" : "gray" }}>{title}<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData[label]?.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData[label]?.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                        </div>
                        <FormGroup className="form-group">
                            <select
                                id={label}
                                className="form-control pt-0 pb-0"
                                name={label}
                                onChange={(e) => this.props.onInputChange(e)}
                                value={this.props.formData[label]}
                            >
                                {
                                    question.options?.length > 0 ?
                                        <>
                                            <option style={{ display: "none" }} />
                                            {this.mapOptions(options)}
                                        </>
                                        :
                                    <></>
                                }
                            </select>
                        </FormGroup>

                    </div>                
                )
            })
        }
    }

    mapOptions = options => {
        if (options?.length > 0) {
            return options.map((option, i) => {
                return (
                    <option key={i} value={option.value}>
                        {option.text}
                    </option>
                );
            });
        } else {
            return null; // Return null or an empty fragment if there are no options
        }
    }


    render() {
        const referredByFriendSelected = this.props.formData.hearAboutUs === "referred";

        return (
            <div className="row mx-0 pt-0" style={{ display: this.props.hideDisplay ? "none" : "", fontFamily: "'Archivo', sans-serif", marginTop: this.props.deviceSize === "sm" ? "-25px" : "" }}>
                <div className="col-lg-12">
                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.firstName.length === 0 ? "red" : "gray" }}>Username<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.userName.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.userName.length === 0 ? "red" : "", display: this.state.anonymous ? "" : "none", position: "relative", top: "-3px" }}>*</span></span>
                            </div>
                            {
                                this.state.anonymous ?
                                    <div>
                                        <FormGroup className="form-group">
                                            <Input
                                                className="form-control"
                                                name="userName"
                                                onChange={(e) => this.props.onNewUserNameInputChange(e)}
                                                disabled={this.props.isSaving}
                                                value={this.props.formData.userName}
                                            />
                                        </FormGroup>
                                        <p style={{ color: "red", display: this.props.validUserName ? "none" : "" }}>A username can only contain letters, numbers, dashes, underscores and periods and be no more than 20 characters.</p>
                                        <p style={{ color: "red", display: this.props.usernameExists ? "" : "none" }}>This username already exists.</p>
                                    </div>
                                    :
                                    <p className="" style={{ display: "inline" }}>{this.props.formData.userName}</p>
                            }
                        </div>
                    </div>
                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.firstName.length === 0 ? "red" : "gray" }}>First Name<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.firstName.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.firstName.length === 0 ? "red" : "",  position: "relative", top: "-3px" }}>*</span></span>
                            </div>

                            <FormGroup className="form-group">
                                <Input
                                    className="form-control"
                                    name="firstName"
                                    onChange={(e) => this.props.onInputChange(e)}
                                    disabled={this.props.isSaving}
                                    value={this.props.formData.firstName}
                                />
                            </FormGroup>

                        </div>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.firstName.length === 0 ? "red" : "gray" }}>Last Name<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.lastName.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.lastName.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                            </div>

                            <FormGroup className="form-group">
                                <Input
                                    className="form-control"
                                    name="lastName"
                                    onChange={(e) => this.props.onInputChange(e)}
                                    disabled={this.props.isSaving}
                                    value={this.props.formData.lastName}
                                />
                            </FormGroup>

                        </div>
                    </div>

                    {
                        this.state.anonymous ?
                            <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                                <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                    <div>
                                        <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.password.length === 0 ? "red" : "gray" }}>Password<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.password.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.password.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                                    </div>

                                    <FormGroup className="form-group">
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            onChange={(e) => this.props.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.props.formData.password}
                                        />
                                    </FormGroup>
                                    <p style={{ color: "red", display: this.props.validPassword ? "none" : "" }}>Passwords must contain a number and a lowercase and uppercase letter and be 8 characers long</p>

                                </div>
                                <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                    <div>
                                        <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.confirmedPassword.length === 0 ? "red" : "gray" }}>Confirm Password<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.confirmedPassword.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.confirmedPassword.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                                    </div>

                                    <FormGroup className="form-group">
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="confirmedPassword"
                                            onChange={(e) => this.props.onInputChange(e)}
                                            value={this.props.formData.confirmedPassword}
                                            disabled={this.props.isSaving}
                                        />
                                    </FormGroup>
                                    <p style={{ color: "red", display: this.props.formData.password.length > 0 && this.props.formData.confirmedPassword.length > 0 && this.props.formData.password !== this.props.formData.confirmedPassword ? "" : "none" }}>Password doesn't match.</p>
                                </div>
                            </div>
                            :
                            <></>
                    }

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        {this.state.anonymous ?
                            <>
                            <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                <div>
                                        <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && (this.props.formData.confirmedEmail?.length === 0 || this.props.formData.email !== this.props.formData.confirmedEmail || this.props.emailExists || !this.props.validEmail) ? "red" : "gray" }}>Email<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && (this.props.formData.confirmedEmail?.length === 0 || this.props.formData.email !== this.props.formData.confirmedEmail) ? "" : "", color: this.props.submitWithUnansweredRequiredFields && (this.props.formData.confirmedEmail?.length === 0 || this.props.formData.email !== this.props.formData.confirmedEmail) ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                                </div>
                                <div>
                                    <FormGroup className="form-group">
                                    <Input
                                        className="form-control"
                                        name="email"
                                        onChange={(e) => this.props.onInputChange(e)}
                                        disabled={this.props.isSaving}
                                        value={this.props.formData.email}
                                    />
                                    </FormGroup>
                                        <p style={{ color: "red", display: this.props.emailExists ? "" : "none" }}>Email is already associated with a RockPorch account.</p>
                                        <p style={{ color: "red", display: !this.props.validEmail ? "" : "none" }}>Email must be in valid format.</p>
                                </div>
                            </div>
                            <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                <div>
                                        <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && (this.props.formData.confirmedEmail?.length === 0 || this.props.formData.email !== this.props.formData.confirmedEmail) ? "red" : "gray" }}>Confirm Email<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && (this.props.formData.confirmedEmail?.length === 0 || this.props.formData.email !== this.props.formData.confirmedEmail) ? "" : "", color: this.props.submitWithUnansweredRequiredFields && (this.props.formData.confirmedEmail?.length === 0 || this.props.formData.email !== this.props.formData.confirmedEmail) ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                                </div>
                                <FormGroup className="form-group">
                                    <Input
                                        type="input"
                                        className="form-control"
                                        name="confirmedEmail"
                                        onChange={(e) => this.props.onInputChange(e)}
                                        value={this.props.formData.confirmedEmail}
                                        disabled={this.props.isSaving}
                                    />
                                </FormGroup>
                                <p style={{ color: "red", display: this.props.formData.email?.length > 0 && this.props.formData.confirmedEmail?.length > 0 && this.props.formData.email?.trim() !== this.props.formData.confirmedEmail?.trim() ? "" : "none" }}>Email doesn't match.</p>
                            </div>
                            </>
                            :
                            <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                <div>
                                    <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.email?.length === 0 ? "red" : "gray" }}>Email<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.email?.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.email?.length === 0 ? "red" : "", display: this.state.anonymous ? "" : "none", position: "relative", top: "-3px" }}>*</span></span>
                                </div>
                                <p className="" style={{ display: "inline" }}>{this.props.formData.email}</p>
                            </div>
                        }

                        {this.state.anonymous ?
                            <></>
                            :
                            <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                <div>
                                    <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.phoneNumber.length === 0 ? "red" : "gray" }}>Phone Number<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.phoneNumber.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.phoneNumber.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                                </div>
                                <FormGroup className="form-group">
                                    <PhoneInput
                                        defaultCountry="US"
                                        name="phoneNumber"
                                        onChange={(e) => this.props.onInputChangePhone(e)}
                                        value={this.props.formData.phoneNumber}
                                        disabled={this.props.isSaving}
                                    />
                                </FormGroup>
                            </div>
                        }
                    </div>

                    {
                        this.state.anonymous ?
                            <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                                <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                                    <div>
                                        <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.phoneNumber.length === 0 ? "red" : "gray" }}>Phone Number<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.phoneNumber.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.phoneNumber.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                                    </div>

                                    <FormGroup className="form-group">
                                        <PhoneInput
                                            defaultCountry="US"
                                            name="phoneNumber"
                                            onChange={(e) => this.props.onInputChangePhone(e)}
                                            value={this.props.formData.phoneNumber}
                                            disabled={this.props.isSaving}
                                        />
                                    </FormGroup>

                                </div>
                            </div>
                                :
                            <></>
                    }

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.country.length === 0 ? "red" : "gray" }}>Country<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.country.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.country.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                            </div>

                            <FormGroup className="form-group">
                                <select
                                    id="country"
                                    className="form-control"
                                    value={this.state.countryId}
                                    onChange={(e) => {
                                        const selectedCountryId = !!(e.target.value) ? e.target.value : "0";
                                        let countries = [{ id: 0, name: "" }, ...this.state.countries];
                                        const selectedCountry = countries.find(country => country.id.toString() === selectedCountryId);
                                        this.fetchStates(parseInt(selectedCountryId) ?? 0)

                                        this.props.onInputChange({ target: { name: "country", value: !!(selectedCountry.name) ? selectedCountry.name : "" } });
                                        this.setState({ countryId: selectedCountryId, countryObj: selectedCountry });
                                    }}
                                >
                                    <option value="">Select Country</option>
                                    {this.state.countries?.map((country) => (
                                        <option key={country.id} value={country.id}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>

                        </div>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.state.length === 0 ? "red" : "gray" }}>State<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.state.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.state.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                            </div>

                            <FormGroup className="form-group">
                                <select
                                    id="state"
                                    className="form-control"
                                    value={this.state.stateId}
                                    onChange={(e) => {
                                        const selectedStateId = !!(e.target.value) ? e.target.value : "0";
                                        let states = [{ id: 0, name: "" }, ...this.state.states];
                                        const selectedState = states.find(state => state.id.toString() === selectedStateId);

                                        this.props.onInputChange({ target: { name: "state", value: !!(selectedState.name) ? selectedState.name : "" } });
                                        this.setState({ stateId: selectedStateId, stateObj: selectedState });
                                    }}
                                >
                                    <option value="">Select State</option>
                                    {this.state.states?.length ? (
                                        this.state.states.map((state) => (
                                            <option key={state.id} value={state.id}>
                                                {state.name}
                                            </option>
                                        ))
                                    ) : this.state.usStates?.length ? (
                                        this.state.usStates.map((state) => (
                                            <option key={state.id} value={state.id}>
                                                {state.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No states available</option>
                                    )}

                                </select>
                            </FormGroup>

                        </div>
                    </div>

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.city.length === 0 ? "red" : "gray" }}>City<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.city.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.city.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                            </div>
                            <FormGroup className="form-group">
                                <Input
                                    className="form-control"
                                    name="city"
                                    onChange={(e) => this.props.onInputChange(e)}
                                    disabled={this.props.isSaving}
                                    value={this.props.formData.city}
                                />
                            </FormGroup> 
                        </div>
                    </div>                                       

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.platform.length === 0 ? "red" : "gray" }}>Primary Social Platform<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.platform.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.platform.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                            </div>

                            <FormGroup className="form-group">
                                <select id="platform"
                                    className="form-control pt-0 pb-0"
                                    name="platform"
                                    onChange={(e) => this.props.onInputChange(e)}
                                    value={this.props.formData["platform"]}
                                >
                                    <option style={{ display: "none" }} />
                                    <option value="Instagram">Instagram</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="YouTube">YouTube</option>
                                    <option value="TikTok">TikTok</option>
                                    <option value="Website/Blog">Website/Blog</option>
                                </select>
                            </FormGroup>
                        </div>

                        {this.showPlatformUserName()}

                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-5" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.followers.length === 0 ? "red" : "gray" }}>Follower or Monthly Traffic Count<span style={{ fontSize: this.props.submitWithUnansweredRequiredFields && this.props.formData.followers.length === 0 ? "" : "", color: this.props.submitWithUnansweredRequiredFields && this.props.formData.followers.length === 0 ? "red" : "", position: "relative", top: "-3px" }}>*</span></span>
                            </div>
                            {this.mapInputFields([{ name: "followers" }])}
                        </div>
                    </div>

                    {this.renderAdditionalPlatforms()}

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Enter a link to your website or blog (if applicable)</span>
                            </div>

                            <FormGroup className="form-group">
                                <div className="input-group">
                                    <div className="input-group-append" style={{ marginRight: "-70px" }}>
                                        <Input
                                            className={`form-control mr-0 pr-0`}
                                            name="websitePrepend"
                                            disabled={true}
                                            value="https://"
                                            style={{ fontSize: "12px", height: "38px" }}
                                        />
                                    </div>
                                    <Input
                                        className="form-control"
                                        name="blog"
                                        onChange={(e) => this.props.onInputChange(e)}
                                        disabled={this.props.isSaving}
                                        value={this.props.formData.blog}
                                        style={{ marginLeft: "-18px" }}
                                    />
                                </div>
                            </FormGroup>

                        </div>
                    </div>     

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        {this.renderDynamicQuestions()}
                    </div>

                    {this.renderEditSectors()}

                </div>
          
                <div class="form-group mt-5 col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" onClick={this.props.handleAgreeToTerms} />
                            <label class="form-check-label" for="gridCheck">
                            I agree to the RockPorch <a href="https://www.rockporch.com/terms/" rel="noreferrer" target="_blank"><strong>Terms of Service</strong></a> and <a href="https://www.rockporch.com/privacy-policy/" target="_blank"><strong>Privacy Policy</strong></a>
                            </label>
                    </div>
                </div>

                <div className={`position-relative d-flex justify-content-center w-100 align-items-center mt-4 submit-btn-div`} style={{ bottom: "9px", gap: "20px" }}>
                    <p style={{ color: "red", display: !this.isReadyToApply() && this.props.submitWithUnansweredRequiredFields ? "" : "none", position: "absolute", bottom: "27px" }}>Please complete all required fields above</p>

                    <ActionButton isLoading={this.props.isSaving} onClick={this.handleSubmit} style={{ filter: this.isReadyToApply() ? "" : "opacity(0.5)", borderRadius: "3px", bottom: "5px", width: "392px" }} className="btn btn-primary">Submit Application</ActionButton>
                </div>
            </div>
        );
    }
}

export default withRouter(CreatorAppProfile);
