import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import ActionButton from "../../components/ActionButton";
import { getRequest, postRequest } from 'sharedUtils/httpUtils';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LoadSpinner from "../../components/LoadSpinner";
import Modal from "components/Modal";
import { getProperties } from "sharedUtils/postFunctions";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import TextField from '@material-ui/core/TextField';
import { Select } from "@material-ui/core";
import { set } from "lodash";

const { DateTime } = require("luxon");

class UpdateAffiliateNetworkPaidDate extends React.Component {
    state = {
        id: 0,
        isFetching: false,
        commissions: [],
        affiliateNetworkPaidDate: DateTime.utc().startOf('day'),
        startDate: DateTime.utc().minus({ months: 1 }).startOf('day'),
        endDate: DateTime.utc().startOf('day'),
        CommissionsTable: {
            title: "Commissions Without AffiliateNetworkPaidDate",
            columns: [
                {
                    name: "commissionId",
                    label: "Commission ID",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    },
                },
                {
                    name: "date",
                    label: "Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }                    },
                },
                {
                    name: "userName",
                    label: "UserName",
                    options: {
                        filter: true,
                        sort: true,
                        display: true,
                    }
                },
                {
                    name: "merchant",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true,
                        display: true,
                    }
                },
                {
                    name: "saleAmount",
                    label: "Sale Amount",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return !!(value) ? "$" + value.toFixed(2) : "$0";
                        },

                    }
                },
                {
                    name: "commissionAmount",
                    label: "Est. Commission",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return !!(value) ? "$" + value.toFixed(2) : "$0";
                        }
                    }
                },
                {
                    name: "returns",
                    label: "Returns",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return !!(value) ? "$" + value.toFixed(2) : "$0";
                        },
                    }
                },
                {
                    name: "commissionStatus",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                        display: false,
                    }
                },
            ],
            options: {
                customToolbarSelect: () => { },
                selectableRows: "multiple",
                sortOrder: {
                    name: 'userId',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/checkboxes/#checkbox-with-formcontrollabel */}
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle MuiTypography-subtitle1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={async (e) => { await this.confirmPublishSelected(selectedRows, displayData); setSelectedRows([]) }}>Publish Selected Content</button>
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        },
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                        root: {
                        }
                    },
                }
            })
        },
    };

    async componentDidMount() {
        this.setState({ isFetching: true });

        await this.fetchCommissionWithNoAffiliateNetworkPaidDate();

        this.setState({ isFetching: false });
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (!!(prevState.startDate) && prevState.startDate !== this.state.startDate) {
            await this.fetchCommissionWithNoAffiliateNetworkPaidDate();
        }
        if (!!(prevState.endDate) && prevState.endDate !== this.state.endDate) {
            await this.fetchCommissionWithNoAffiliateNetworkPaidDate();
        }
    }

    fetchCommissionWithNoAffiliateNetworkPaidDate = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let now = DateTime.now();
        let offset = now.offset;
    
        let getUrl = "api/Merchant/GetCommissionWithNoAffiliateNetworkPaidDate";

        if (startDate) {
            getUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getUrl += getUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getUrl += `&offset=${offset}`
        const commissions = await getRequest(getUrl);
        this.setState({ commissions });
    }

    handleAffilateNetworkPaidDateChange = (event) => {
        const affiliateNetworkPaidDate = DateTime.fromISO(event.target.value).toUTC().startOf('day');
        this.setState({
            affiliateNetworkPaidDate
        });
    };

    confirmPublishSelected = (selectedRows, displayData) => {
        const confirmDelete = window.confirm(`SELECTED COMMISSIONS will be SAVED with ${this.state.affiliateNetworkPaidDate.toFormat('MM-dd-yyyy')} as AffiliateNetworkPaidDate ...`);
        if (confirmDelete) {
            this.handlePublish(selectedRows, displayData);
        }
    }

    handleStartDateChange = (event) => {
        const startDate = DateTime.fromISO(event.target.value).toUTC().startOf('day');
        this.setState({
            startDate
        });
    };

    handleEndDateChange = (event) => {
        const endDate = DateTime.fromISO(event.target.value).toUTC().startOf('day');
        this.setState({
            endDate
        });
    };

    handleWeekToDate = () => {
        const startDate = DateTime.utc().minus({ weeks: 1 }).startOf('day');
        const endDate = DateTime.utc().startOf('day');
        this.setState({ startDate, endDate });
    };

    handleMonthToDate = () => {
        const startDate = DateTime.utc().minus({ months: 1 }).startOf('day');
        const endDate = DateTime.utc().startOf('day');
        this.setState({ startDate, endDate });
    };

    handleYearToDate = () => {
        const startDate = DateTime.utc().minus({ years: 1 }).startOf('day');
        const endDate = DateTime.utc().startOf('day');
        this.setState({ startDate, endDate });
    };

    handlePublish = async (selectedRows = null, displayData = null) => {
        this.setState({ loading: true });
        let commissionIds = [];
        if (!!(selectedRows) && !!(selectedRows.data) && selectedRows.data.length > 0) {
            const selectedRowsData = selectedRows.data;
            commissionIds = selectedRowsData.length > 0 ? selectedRowsData.map(row => displayData[row.index].data[0]) : [];
        }
        const publishUrl = "api/Merchant/UpdateCommissionsWithAffiliateNetworkPaidDate";

        const payload = {
            commissionIds,
            affiliateNetworkPaidDate: this.state.affiliateNetworkPaidDate.toFormat("MM/dd/yyyy")
        }

        var published = await postRequest(publishUrl, payload);
        if (!!(published)) {
            this.setState({ publishError: false });
        } else {
            this.setState({ publishError: true });
        }

        await this.fetchCommissionWithNoAffiliateNetworkPaidDate();

        this.setState({ loading: false });
    };


    render() {
        if (!this.props.display) {
            return <></>
        }

        return (
            <>
                
                <section>
                    <div className="container-fluid">
                        {
                            this.state.isFetching ?
                                <LoadSpinner />
                                :
                                <div className="row">
                                    <div className="col mx-5">
                                        <div className="text-right mb-2 d-flex justify-content-between">
                                            <div>
                                                <div className="d-flex flex-column justify-content-start mb-3" style={{ width: "200px" }}>
                                                    <label className="mb-0" style={{ textAlign: "left" }}>Start Date:</label>
                                                    <TextField
                                                        id="start-date"
                                                        type="date"
                                                        value={this.state.startDate.toISODate()}
                                                        onChange={this.handleStartDateChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            style: { fontFamily: "'Archivo', sans-serif" }
                                                        }} />
                                                </div>
                                                <div className="d-flex flex-column justify-content-start mb-3   " style={{ width: "200px" }}>
                                                    <label className="mb-0" style={{ textAlign: "left" }}>End Date:</label>
                                                    <TextField
                                                        id="end-date"
                                                        type="date"
                                                        value={this.state.endDate.toISODate()}
                                                        onChange={this.handleEndDateChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            style: { fontFamily: "'Archivo', sans-serif" }
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between">
                                                <div className="d-flex w-100 justify-content-end pr-2" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                    <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                                    <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                                    <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                                                </div>
                                                <div className="d-flex flex-column justify-content-start mb-3" style={{ width: "200px" }}>
                                                    <label className="mb-0" style={{ textAlign: "left" }}>Affiliate Network Paid Date:</label>
                                                    <TextField
                                                        id="anpd"
                                                        type="date"
                                                        value={this.state.affiliateNetworkPaidDate.toISODate()}
                                                        onChange={this.handleAffilateNetworkPaidDateChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            style: { fontFamily: "'Archivo', sans-serif" }
                                                        }} />
                                                </div>           
                                            </div>                                                                              
                                        </div>
                                        <p style={{ color: "red", display: this.state.deleteError ? "" : "none" }} className="text-right">ERROR DELETING CONTENT: try again</p>
                                        <p style={{ color: "red", display: this.state.publishError ? "" : "none" }} className="text-right">ERROR PUBLISHING CONTENT: try again</p>
                                        <ThemeProvider theme={this.state.CommissionsTable.getTheme()}>
                                            <MUIDataTable
                                                title={this.state.CommissionsTable.title}
                                                data={this.state.commissions}
                                                columns={this.state.CommissionsTable.columns}
                                                options={this.state.CommissionsTable.options}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                        }
                    </div>
                </section>

            </>
        )
    }
}

export default UpdateAffiliateNetworkPaidDate;