import React, { Component } from "react";
import _ from "lodash";
import LoadSpinner from "./LoadSpinner";
import ImageWrapper from "components/ImageWrapper";
import { titleCase } from "sharedUtils/productUtils";
import CommissionDetailItemRowMobile from "views/Account/AnalyticsByCommissionDetailItemRowMobile";
import NoResults from "components/NoResults";
import { getRequest } from "sharedUtils/httpUtils"; 
import toast, { Toaster } from 'react-hot-toast';

const { DateTime } = require("luxon");


class AnalyticsByCommissionDetailItemTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: DateTime.utc().minus({ months: 1 }).startOf('day'),
            endDate: DateTime.utc().startOf('day'),
            tab: 1,
            show: false,
            showSales: false,
            clicked: null,
            salesClicked: null,
        };
    }

    componentDidMount = async () => {
        await this.props.resetPerformanceTabs();
        await this.props.getCommissionDetailItemsAnalytics(this.props.startDate, this.props.endDate);
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.userChanged != null && prevProps.userChanged != this.props.userChanged && this.props.userChanged) {
            await this.props.getCommissionDetailItemsAnalytics(this.props.startDate, this.props.endDate);
        }
        if (prevProps.aggregateChanged != null && prevProps.aggregateChanged !== this.props.aggregateChanged && this.props.display) {
            await this.props.getCommissionDetailItemsAnalytics(this.props.startDate, this.props.endDate);
        }
        if (prevProps.browseChanged != null && prevProps.browseChanged !== this.props.browseChanged && this.props.display) {
            await this.props.getCommissionDetailItemsAnalytics(this.props.startDate, this.props.endDate);
        }
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false, clicked: null });
        this.props.clearPerformance();
        this.props.clearSourceSort();
    }

    showSalesModal = (e) => {
        e.preventDefault();
        this.setState({ showSales: !this.state.showSales });
    };

    hideSalesModal = () => {
        this.setState({ showSales: false, salesClicked: null });
        this.props.clearPerformance();
        this.props.clearSalesSourceSort();
    }

    displayMerchantMobile = () => {
        if (this.props.commissionDetailItemsAnalytics?.length === 0) {
            return (
                <div className="mt-3">
                    <NoResults />
                </div>
            )
        }

        const cdiData = {
            imageUrl: "",
            name: "",
            price: "",
            sale: "",
            salePrice: "",
            integrated: "",
            merchant: "",
            clicks: "clicks",
            sales: "sales",
            conversionRate: "conversions"
        };

        if (this.props.commissionDetailItemsAnalytics?.length > 0) {
            const analytics = [cdiData, ...this.props.commissionDetailItemsAnalytics];
            return analytics?.map((item, i) => {
                return (
                    <div key={i}>
                        <CommissionDetailItemRowMobile
                            handleGetLink={this.props.handleGetLink}
                            item={item}
                            productKey={i}
                            key={i}
                            edit={() => { }}
                            hide={() => { }}
                            toggleProductModal={() => { }}
                            toggleShareModal={() => { }}
                            isUserMerchant={false}
                            editClick={() => { }}
                            hideClick={() => { }}
                            deviceSize={this.props.deviceSize}
                            handleSortSelect={this.props.handleCommissionDetailItemsSortSelect}
                            sortDescending={this.props.commissionDetailItemsSortDescending}
                            selectOptions={this.props.commissionDetailItemsSelectOptions}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            addUserMerchantProductIfNotAddedAlreadyAndGetLink={this.addUserMerchantProductIfNotAddedAlreadyAndGetLink}
                        />
                    </div>
                )

            })
        }
        return <></>;
    }

    addUserMerchantProductIfNotAddedAlreadyAndGetLink = async (merchantProductId) => {
        const url = 'Products/AddUserMerchantProductForAnalyticsCommissionDetailItem?merchantProductId=' + merchantProductId + '&userId=' + this.props.userId ?? this.props.loggedInUser.id;

        let result = await getRequest(url);
        if (!(result)) {
            this.getLinkError();
        }

        this.handleGetLink("https://" + window.location.host + "/prdlink/" + result.shareCode);
    };

    handleGetLink = (shareLink) => {
        navigator.clipboard.writeText(shareLink);

        toast.success(
            <div className="p-2">
                <h4 className="text-center">Commissionable Link Copied to Clipboard!</h4>
                <p className="text-center mx-auto px-2 pt-2 pb-">Your commissionable link has been copied to clipboard and is ready to be shared</p>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    getLinkError = () => {
        toast.error(
            <div className="p-2">
                <h4 className="text-center">Product currently unavailable</h4>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }


    displayTableHeader = () => {
        let headers = ["Product", "Merchant", "Sales Count", "Estimated Commission", "Price"];

        return headers.map((header, i) => {
            const options = [
                { label: "Name", value: 1 },
                { label: "Merchant", value: 2 },
                { label: "Sales", value: 3 },
                { label: "Estimated Commission", value: 4 },
                { label: "Price", value: 5 },
            ]

            if (header === "Product") {
                return (
                    <th key={i} onClick={() => this.props.handleCommissionDetailItemsSortSelect(options[0])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.commissionDetailItemsSortDescending ? "-4px" : "9px", left: this.props.commissionDetailItemsSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.commissionDetailItemsSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.commissionDetailItemsSelectOptions.currentValue.label === "Name" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Merchant") {
                return (
                    <th key={i} onClick={() => this.props.handleCommissionDetailItemsSortSelect(options[1])} style={{ paddingLeft: "7px", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.commissionDetailItemsSortDescending ? "-4px" : "9px", left: this.props.commissionDetailItemsSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.commissionDetailItemsSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.commissionDetailItemsSelectOptions.currentValue.label === "Merchant" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Sales Count") {
                return (
                    <th key={i} onClick={() => this.props.handleCommissionDetailItemsSortSelect(options[2])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.commissionDetailItemsSortDescending ? "-4px" : "9px", left: this.props.commissionDetailItemsSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.commissionDetailItemsSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.commissionDetailItemsSelectOptions.currentValue.label === "Sales" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Conversion") {
                return (
                    <th key={i} onClick={() => this.props.handleCommissionDetailItemsSortSelect(options[3])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none", textAlign: "center" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.commissionDetailItemsSortDescending ? "-4px" : "9px", left: this.props.commissionDetailItemsSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.commissionDetailItemsSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.commissionDetailItemsSelectOptions.currentValue.label === "Conversion Rate" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Price") {
                return (
                    <th key={i} onClick={() => this.props.handleCommissionDetailItemsSortSelect(options[4])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none", textAlign: "center" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.commissionDetailItemsSortDescending ? "-4px" : "9px", left: this.props.commissionDetailItemsSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.commissionDetailItemsSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.commissionDetailItemsSelectOptions.currentValue.label === "Price" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Estimated Commission") {
                return (
                    <th key={i} onClick={() => this.props.handleCommissionDetailItemsSortSelect(options[3])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none", textAlign: "center" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.commissionDetailItemsSortDescending ? "-4px" : "9px", left: this.props.commissionDetailItemsSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.commissionDetailItemsSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.commissionDetailItemsSelectOptions.currentValue.label === "Estimated Commission" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else {
                return (
                    <th key={i} style={{ paddingLeft: "12px", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", border: "none" }}>
                        <strong>{header}</strong>
                    </th>
                );
            }
        });
    };

    displayTableRows = () => {
        if (this.props.commissionDetailItemsAnalytics?.length > 0) {
            return this.props.commissionDetailItemsAnalytics.map((item, i) => {                

                return (
                    <tr key={i} className="hover-background"
                        style={{ cursor: "pointer", borderBottom: this.state.hoverRow === i + 1 ? "1px solid gray" : "" }}
                        onClick={() => { this.addUserMerchantProductIfNotAddedAlreadyAndGetLink(item.merchantProductId) }}
                    >
                        <td style={{ background: "white", maxWidth: "210px", wordWrap: "break-word", border: "none" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ImageWrapper src={item.imageUrl} style={{ objectFit: "contain" }} />
                                <h6>{titleCase(item.name.trim())}</h6>
                            </div>
                        </td>

                        <td className="pr-5 brand-primary-color" onClick={(e) => { } } style={{ textAlign: "center", maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span>{item.merchant}</span>
                        </td>

                        <td className="pr-5" onClick={(e) => { }} style={{ textAlign: "center", maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span>{item.sales}</span>
                        </td>

                        <td className=" brand-primary-color" style={{ textAlign: "center", maxWidth: "100px", wordWrap: "break-word", border: "none" }}>
                            <span>${item.estimatedCommission.toFixed(2)}</span>
                        </td>

                        <td className=" brand-primary-color" style={{ textAlign: "center", maxWidth: "100px", wordWrap: "break-word", border: "none" }}>
                            <span>${item.price.toFixed(2)}</span>
                        </td>

                    </tr>
                )
            })
        }
    }

    render() {
        return (
            <>
                <Toaster />
                {
                    this.props.analyticsByCommissionDetailItemLoading ?
                        <LoadSpinner /> :
                        <section>                            
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col" style={{ padding: "0" }}>

                                        {["sm", "md"].includes(this.props.deviceSize) ?
                                            this.displayMerchantMobile()
                                            :
                                            this.props.commissionDetailItemsAnalytics?.length === 0 ?
                                                <div className="mt-3">
                                                    <NoResults />
                                                </div>
                                                :
                                                <div className="table-responsive product-table" style={{ overflow: "visible" }}>
                                                    <table className="table" style={{ border: 0 }}>
                                                        <thead>
                                                            <tr>
                                                                {this.displayTableHeader()}
                                                            </tr>
                                                        </thead>


                                                        <tbody>
                                                            {this.displayTableRows()}
                                                        </tbody>
                                                    </table>
                                                </div>}
                                    </div>
                                </div>
                            </div>
                        </section>
                }
            </>
        );
    }
}

export default AnalyticsByCommissionDetailItemTable;